import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'
import { createI18n } from 'vue-i18n'
import messages from './lang'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import "bootstrap/dist/css/bootstrap.min.css";

import 'flag-icons/css/flag-icons.min.css'

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true,
  messages,
})

const app = createApp(App)
app.use(router)
app.use(i18n)
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.config.globalProperties.axios = axios

app.mount('#app')

import "bootstrap/dist/js/bootstrap.min";