import es from './translations/es.json'
import en from './translations/en.json'
/*import fr from './translations/fr.json'
import it from './translations/it.json'
import de from './translations/de.json'
import ca from './translations/ca.json'*/

export default {
  //ca,
  //de,
  es,
  en,
  //fr,
  //it,
}
