<template>
  <div class="modal" :class="{ 'show':props.visible, '':!props.visible}" tabindex="-1" :visible="props.visible" @close="confirm">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ props.title }}</h5>
        </div>
        <div class="modal-body">
          <p>{{ props.body }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="confirm">{{  props.button }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalActivateAudio'
}
</script>

<script setup>
import { defineEmits } from 'vue'
const props = defineProps({
  title: String,
  body: String,
  button: String,
  visible: Boolean
})

const emits = defineEmits(['confirm'])

const confirm = () => {
  emits('confirm', true)
}

</script>

<style>
.modal-footer {
  justify-content: center;
}
.show {
  display: block !important;
}
</style>
